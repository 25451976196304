import * as React from "react";
import { graphql } from "gatsby";
import { Header, Container, Segment, Card } from "semantic-ui-react";
import Title from "../components/Title";
import { ConnectYamlGroupConnection } from "../graphql-types";
import { LayoutProps, withLayout } from "../components/Layout";
import ConnectCard from "../components/ConnectCard";

const pageTitle = "Connects";

interface ConnectsPageProps extends LayoutProps {
  data: {
    eventConnects: ConnectYamlGroupConnection;
    communityConnects: ConnectYamlGroupConnection;
  };
}

const ConnectsPage: React.FC<ConnectsPageProps> = ({ data }) => {
  const events = data.eventConnects.nodes;
  const communities = data.communityConnects.nodes

  return (
    <Container fluid className="bg-sabo">
      <Container>
        <Title title={pageTitle} />
        <Segment vertical padded="very">
          <p>
            サイボウズが応援している団体やイベントの一覧です（2020年 〜 ）。
          </p>
          <p>
            サイボウズ社員が登壇したイベントも含まれます。
          </p>
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2">コミュニティ</Header>
          <Card.Group doubling>
            {
              communities.map(item =>
                <ConnectCard key={item.id} connect={item} />)
            }
          </Card.Group>
        </Segment>

        <Segment vertical padded="very">
          <Header as="h2">イベント</Header>
          <Card.Group doubling>
            {
              events.map(item =>
                <ConnectCard key={item.id} connect={item} />)
            }
          </Card.Group>
        </Segment>
      </Container>
    </Container>
  );
};

export default withLayout(ConnectsPage, pageTitle);

export const pageQuery = graphql`
query ConnectsEvents {
  eventConnects: allConnectYaml(
    filter: {
      category: { eq: "event" }
    }
    sort: { fields: date, order: DESC }
  ) {
    nodes {
      id
      name
      logo
      url
      date
    }
  }

  communityConnects: allConnectYaml(
    filter: {
      category: { eq: "community" }
    }
  ) {
    nodes {
      id
      name
      logo
      url
    }
  }
}
`;
